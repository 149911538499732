var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"b_main_banner"},[(_vm.isNewsbreaker)?_c('newsbreaker',{attrs:{"newsbreaker":_vm.content.newsbreaker}}):_vm._e(),_vm._v(" "),(_vm.isSlider)?_c('slider',{key:_vm.sliderKey,attrs:{"slides":_vm.content.slides}}):_vm._e()],1),_vm._v(" "),_c('ad',{attrs:{"name":"homepage-balloon"}}),_vm._v(" "),_c('div',{staticClass:"b_association"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_title second_font text_center"},[_vm._v("\n                כאן באגודה\n            ")]),_vm._v(" "),_c('div',{staticClass:"association_wrapper"},[(_vm.isTile)?_c('div',{staticClass:"association_list flex"},[_vm._l((_vm.content.tiles),function(tile,index){return _c('div',{key:index,staticClass:"association_item"},[_c(tile.premiumOnly && !_vm.isPremiumUser
                                    ? 'button'
                                    : 'nuxt-link',{tag:"component",staticClass:"association_block text_center",class:{
                                private: tile.premiumOnly,
                                unlocked: _vm.isPremiumUser
                            },attrs:{"to":tile.redirect}},[_c('div',{staticClass:"block_image"},[_c('div',{staticClass:"image_wrapper"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path",value:(
                                            _vm.getTileImage(tile)
                                        ),expression:"\n                                            getTileImage(tile)\n                                        "}],key:_vm.isPremiumUser ? 'YES' : 'NO'})]),_vm._v(" "),_c('div',{staticClass:"block_title second_font"},[_vm._v("\n                                    "+_vm._s(tile.title)+"\n                                ")])])])],1)}),_vm._v(" "),_c('ad',{attrs:{"name":"homepage-tile"}})],2):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }