<template>
    <div>
        <div class="b_main_banner">
            <newsbreaker
                v-if="isNewsbreaker"
                :newsbreaker="content.newsbreaker"
            />

            <slider v-if="isSlider" :key="sliderKey" :slides="content.slides" />
        </div>

        <ad name="homepage-balloon" />

        <div class="b_association">
            <div class="container">
                <div class="section_title second_font text_center">
                    כאן באגודה
                </div>
                <div class="association_wrapper">
                    <div v-if="isTile" class="association_list flex">
                        <div
                            v-for="(tile, index) in content.tiles"
                            :key="index"
                            class="association_item"
                        >
                            <component
                                :is="
                                    tile.premiumOnly && !isPremiumUser
                                        ? 'button'
                                        : 'nuxt-link'
                                "
                                :to="tile.redirect"
                                class="association_block text_center"
                                :class="{
                                    private: tile.premiumOnly,
                                    unlocked: isPremiumUser
                                }"
                            >
                                <div class="block_image">
                                    <div class="image_wrapper">
                                        <picture
                                            :key="isPremiumUser ? 'YES' : 'NO'"
                                            v-full-image-path="
                                                getTileImage(tile)
                                            "
                                        />
                                    </div>
                                    <div class="block_title second_font">
                                        {{ tile.title }}
                                    </div>
                                </div>
                            </component>
                        </div>

                        <ad name="homepage-tile" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StatusCodes } from 'http-status-codes';
import Ad from '@/components/ads';
import headMixin from '@/mixins/head.mixin.js';
import Slider from '@/components/homepage/Slider';
import Newsbreaker from '@/components/homepage/Newsbreaker';

export default {
    components: {
        Ad,
        Slider,
        Newsbreaker
    },

    mixins: [headMixin],

    async asyncData({ store: { dispatch }, error }) {
        try {
            const { value, meta } = await dispatch('homepages/getHomepage');

            return { content: value, meta, sliderKey: 0 };
        } catch (err) {
            if (
                err.response &&
                err.response.status !== StatusCodes.INTERNAL_SERVER_ERROR
            ) {
                return error({ statusCode: err.response.status });
            }

            console.error(err);

            return error({ statusCode: StatusCodes.INTERNAL_SERVER_ERROR });
        }
    },

    computed: {
        ...mapGetters({
            isPremiumUser: 'isPremiumUser',
            selectedCampus: 'campuses/selectedCampus'
        }),

        isNewsbreaker() {
            return this.content.newsbreaker?.length;
        },

        isSlider() {
            return this.content.slides?.length;
        },

        isTile() {
            return this.content.tiles?.length;
        }
    },

    watch: {
        selectedCampus() {
            this.refetchHomePage();
        }
    },

    methods: {
        ...mapActions({ getHomepage: 'homepages/getHomepage' }),

        async refetchHomePage() {
            try {
                const { value, meta } = await this.getHomepage();

                this.content = value;
                this.meta = meta;
                this.sliderKey++;
            } catch (error) {
                console.error(error);

                this.$toasted.global.error();
            }
        },

        getTileImage(tile) {
            const { premiumOnly, premiumOnlyImage, image } = tile;

            if (!premiumOnly) {
                return image;
            }

            return this.isPremiumUser ? image : premiumOnlyImage;
        }
    }
};
</script>
