<template>
    <div class="banner_head_wrap">
        <div class="container">
            <div class="banner_head text_center">
                <a :href="activeNews.link" target="_blank">
                    {{ activeNews.text }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        newsbreaker: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            activeNewsIndex: 0,
            interval: null
        };
    },

    computed: {
        ...mapGetters({
            selectedCampus: 'campuses/selectedCampus'
        }),

        activeNews() {
            return this.newsbreaker[this.activeNewsIndex];
        }
    },

    watch: {
        selectedCampus() {
            this.activeNewsIndex = 0;
        }
    },

    mounted() {
        this.interval = setInterval(this.handleNextNews, 5000);
    },

    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },

    methods: {
        handleNextNews() {
            const isLastNews =
                this.activeNewsIndex === this.newsbreaker.length - 1;

            this.activeNewsIndex = isLastNews ? 0 : this.activeNewsIndex + 1;
        }
    }
};
</script>
