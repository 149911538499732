<template>
    <div class="banner">
        <client-only>
            <vue-slick-carousel
                ref="carousel"
                class="banner_slider"
                :arrows="false"
                :slides-to-show="1"
                :slides-to-scroll="1"
                :touch-threshold="150"
                :dots="true"
                :fade="true"
                :speed="300"
                :css-ease="'linear'"
                :autoplay="true"
                :autoplay-speed="4000"
            >
                <div
                    v-for="(slide, index) in slides"
                    :key="index"
                    class="banner_slide "
                >
                    <div
                        v-full-background-image-path="slide.image"
                        class="banner_image bg_image cover"
                    />
                    <div class="container">
                        <div class="banner_content flex">
                            <div class="content">
                                <div class="banner_title second_font">
                                    {{ slide.upperText }}
                                </div>
                                <div class="description second_font">
                                    {{ slide.lowerText }}
                                </div>
                                <div class="btn_wrapper">
                                    <nuxt-link
                                        :to="slide.buttonLink"
                                        class="btn btn_template"
                                    >
                                        {{ slide.buttonText }}
                                    </nuxt-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-slick-carousel>
        </client-only>

        <button
            class="slider_pause"
            :class="{ active: isSliderPaused }"
            type="button"
            aria-label="Pause/Start slider"
            @click="toggleSlider"
        />
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    components: {
        VueSlickCarousel
    },

    props: {
        slides: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            isSliderPaused: false
        };
    },

    methods: {
        toggleSlider() {
            this.isSliderPaused = !this.isSliderPaused;

            this.isSliderPaused
                ? this.$refs.carousel.pause()
                : this.$refs.carousel.play();
        }
    }
};
</script>
